import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ContractCard.css';

function Calendar({ variable, setContractVariables, contractVariables }) {
  const selectedDate = contractVariables.datesAndSignatures[variable]
    ? parseSpanishDate(contractVariables.datesAndSignatures[variable]) // Convierte a Date si existe
    : null;

  function parseSpanishDate(dateString) {
    const parts = dateString.split(' ');
    const day = parseInt(parts[1], 10);
    const month = new Date(Date.parse(`${parts[3]} 1, 2000`)).getMonth();
    const year = parseInt(parts[5], 10);
    return new Date(year, month, day);
  }

  return (
    <div className="form-group flex items-center">
      <label className="text-lg font-semibold w-[30%] mr-3">
        {variable.toUpperCase()}:
      </label>
      <DatePicker
        selected={selectedDate} // Pasa selectedDate aquí, que es un objeto Date
        onChange={(date) => {
          const formatter = new Intl.DateTimeFormat('es-ES', {
            dateStyle: 'full',
          });

          setContractVariables({
            ...contractVariables,
            datesAndSignatures: {
              ...contractVariables.datesAndSignatures,
              [variable]:
                date && formatter.format(date) ? formatter.format(date) : date, // Guarda como ISO para ser compatible
            },
          });
        }}
        dateFormat="dd/MM/yy"
        placeholderText="Elige una fecha"
      />
    </div>
  );
}

export default Calendar;
