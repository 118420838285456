export const Format_Firms_POA = (formattedLines) => {
  const participant_and_Testify_2 = `<div style="display: flex; flex-direction: column-reverse;  justify-content: space-around; padding: 0px;">
        ${formattedLines[formattedLines.length - 1]} ${formattedLines[formattedLines.length - 2]}
        </div>`;

  const participant_and_Testify_1 = `<div style="display: flex; flex-direction: column-reverse;  justify-content: space-around; padding: 0px;">
        ${formattedLines[formattedLines.length - 3]} ${formattedLines[formattedLines.length - 4]}
        </div>`;

  formattedLines.pop();
  formattedLines.pop();
  formattedLines.pop();
  formattedLines.pop(); // elimina las 2 secciones de firma sin manipular del arreglo del contrato principal

  console.log('NDA', formattedLines);

  return [
    `<div style="display: flex;  justify-content: space-between; padding: 0px;">
        ${participant_and_Testify_1} ${participant_and_Testify_2}
        </div>`,
  ];
};

export const Format_Firms_NDA = (formattedLines) => {
  const participant_and_Testify_2 = `<div style="display: flex; flex-direction: column-reverse;  justify-content: space-around; padding: 0px;">
    ${formattedLines[formattedLines.length - 1]}
    </div>`;

  const participant_and_Testify_1 = `<div style="display: flex; flex-direction: column-reverse;  justify-content: space-around; padding: 0px;">
    ${formattedLines[formattedLines.length - 2]}
    </div>`;

  formattedLines.pop();
  formattedLines.pop(); // elimina las 2 secciones de firma sin manipular del arreglo del contrato principal

  //   console.log('Carta Poder', formattedLines);
  return [
    `<div style="display: flex;  justify-content: space-between; padding: 0px;">
        ${participant_and_Testify_1} ${participant_and_Testify_2}
        </div>`,
  ];
};
