/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';

const apiUrl = process.env.REACT_APP_API_URL;

const initialFormData = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  errors: {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
  success: '',
};

const initialPasswordVisibility = {
  currentPassword: false,
  newPassword: false,
  confirmPassword: false,
};

const ResetPassword = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [showPassword, setShowPassword] = useState(initialPasswordVisibility);

  const tokenLS = localStorage.getItem('authToken');
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tokenLS && token) navigate('/login');
  }, [tokenLS, token, navigate]);

  // Manejar cambios en los campos
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      errors: {
        ...prev.errors,
        [name]: '', // Limpiar errores al escribir
      },
    }));
  };

  // Mostrar/Ocultar contraseña
  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Validar campos
  const validateFields = () => {
    const errors = {};
    if (!formData.currentPassword)
      errors.currentPassword = 'La contraseña actual es obligatoria.';
    if (!formData.newPassword)
      errors.newPassword = 'La nueva contraseña es obligatoria.';
    if (formData.newPassword !== formData.confirmPassword)
      errors.confirmPassword = 'Las contraseñas no coinciden.';

    setFormData((prev) => ({
      ...prev,
      errors,
    }));
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, success: '', errors: {} }));

    if (!validateFields()) return;

    try {
      await axios.put(
        `${apiUrl}/auth/reset-password/`,
        {
          password: formData.currentPassword,
          newPassword: formData.newPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setFormData({
        ...initialFormData,
        success: '¡Contraseña actualizada exitosamente!',
      });
    } catch (err) {
      setFormData((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          general: 'Hubo un error al actualizar la contraseña.',
        },
      }));
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-[#f8f9ff] to-white p-4">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg shadow-md w-full max-w-md"
      >
        <h2 className="text-2xl font-bold gradient-text mb-4 text-center">
          Restablecer Contraseña
        </h2>

        {/* Contraseña actual */}
        <div className="mb-4 relative">
          <label
            htmlFor="currentPassword"
            className="block text-sm font-medium text-[rgb(107,100,100)]"
          >
            Contraseña Actual
          </label>
          <input
            type={showPassword.currentPassword ? 'text' : 'password'}
            id="currentPassword"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            required
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-[rgb(23,43,133)]"
          />
          <span
            onClick={() => togglePasswordVisibility('currentPassword')}
            className="absolute right-2 top-9 cursor-pointer"
          >
            {!showPassword.currentPassword ? <EyeOff /> : <Eye />}
          </span>
          {formData.errors.currentPassword && (
            <p className="text-red-500 text-sm">
              {formData.errors.currentPassword}
            </p>
          )}
        </div>

        {/* Nueva contraseña */}
        <div className="mb-4 relative">
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium text-[rgb(107,100,100)]"
          >
            Nueva Contraseña
          </label>
          <input
            type={showPassword.newPassword ? 'text' : 'password'}
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            required
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-[rgb(23,43,133)]"
          />
          <span
            onClick={() => togglePasswordVisibility('newPassword')}
            className="absolute right-2 top-9 cursor-pointer"
          >
            {!showPassword.newPassword ? <EyeOff /> : <Eye />}
          </span>
          {formData.errors.newPassword && (
            <p className="text-red-500 text-sm">
              {formData.errors.newPassword}
            </p>
          )}
        </div>

        {/* Confirmar contraseña */}
        <div className="mb-4 relative">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-[rgb(107,100,100)]"
          >
            Confirmar Nueva Contraseña
          </label>
          <input
            type={showPassword.confirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-[rgb(23,43,133)]"
          />
          <span
            onClick={() => togglePasswordVisibility('confirmPassword')}
            className="absolute right-2 top-9 cursor-pointer"
          >
            {!showPassword.confirmPassword ? <EyeOff /> : <Eye />}
          </span>
          {formData.errors.confirmPassword && (
            <p className="text-red-500 text-sm">
              {formData.errors.confirmPassword}
            </p>
          )}
        </div>

        {/* Mensajes de éxito/error */}
        {formData.errors.general && (
          <p className="text-red-500 text-sm mb-4 text-center">
            {formData.errors.general}
          </p>
        )}
        {formData.success && (
          <p className="text-green-500 text-sm mb-4 text-center">
            {formData.success}
          </p>
        )}

        {/* Botón de envío */}
        <button
          type="submit"
          className="mt-4 mx-auto animated-gradient text-white px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 flex items-center justify-center group border-none"
        >
          Actualizar Contraseña
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
